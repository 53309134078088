.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  transition: margin-left 1s ease;
  margin-left: calc(3rem - 100%)

}

.App.visible {
  margin-left: unset;
}

.content-body {
  flex-grow: 2;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
}

.content-column {
  flex-grow: 2;
  font-family: Erika, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
  margin-left: 2rem;
  margin-right: 2rem;
  overflow: auto;
}

.content-column.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: Erika, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
  font-size: 2vh;
  width: 3rem;
  flex-shrink: 0;
}

.nav-title {
  writing-mode: vertical-rl;
  text-transform: uppercase;
  margin: .5rem;
}

.tab-item {
  writing-mode: vertical-rl;
  text-transform: uppercase;
  text-align: center;
  margin-right: .5rem;
  padding-left: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-right: .5rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 12vh;
  transition: padding-left 0.5s ease;
}

.tab-link {
  color: black;
  text-decoration: none;
}

.tab-item:hover {
  padding-left: 1rem;
}

.corner-wrapper {
  height: .5rem
}

.top-corner-overlay {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 10px;
}

.bottom-corner-overlay {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  border-top-left-radius: 10px;
}

.hr {
  border-bottom: 1px solid black;
  height: 1px;
  width: 100%;
  flex-grow: 2;
}

.seamless-input {
  display: block;
  background: none;
  border: none;
  font-size: 16px;
  font-family: Erika, sans-serif;
  margin-top: .5em;
  margin-bottom: .5em;
  transition: 1s all ease;
  width: 100%;
}

.seamless-input:invalid {
  background-color: white;
  color: red;
}

.flex-input {
  display: block;
  background: none;
  border: none;
  font-size: 16px;
  font-family: Erika, sans-serif;
  margin-top: .5em;
  margin-bottom: .5em;
  transition: 1s all ease;
  width: 100%;
}

.seamless-input.pending {
  background-color: white;
}

.seamless-input:focus {
  opacity: .7;
  outline: none;
}

.typefaces-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cut-container {
  border-radius: 10px;
  padding: .5rem;
  border: 1px solid black;
  margin-bottom: .5rem;
  transition: 1s background-color;
}

h1, h2 {
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
}

button {
  background: unset;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 16px;
  font-family: Erika, sans-serif;
  color: black;
}

button:hover {
  cursor: pointer;
  opacity: .8;
}

button:active {
  background-color: black;
  color: white;
  opacity: 1;
}

button:focus {
  /*color: white;*/
  opacity: 1;
  border: 1px solid white;
  outline: none;
}

button.medium {
  width: 200px;
  margin-right: 1rem;
}

.button-container {
  margin-top: 1em;
  margin-bottom: 1em;
}

.button-container-small {
  margin-top: .2em;
  margin-bottom: .2em;
}

.cut-content-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
}

.cut-meta-container {
  flex: 50;
  padding-right: .5rem;
  display: flex;
  flex-direction: column;
}

.cut-meta-fields {
  flex-grow: 2;
}

.cut-assets-container {
  flex: 50;
  border-left: 1px solid black;
  padding-left: .5rem;
}

.asset-container {
  margin-bottom: .5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.asset-container.drag-active {
  background-color: white;
}

.asset-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.asset-name {
  flex-grow: 2;
  overflow: auto;
  display: flex;
}

.asset-action {
  cursor: pointer;
}

.asset-action:hover {
  filter: invert(1);
}

.toggle {
  margin-top: .5em;
  margin-bottom: .5em;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  height: 2em;
}

.toggle > input {
  margin-right: .5rem;
}

.dropzone {
  margin: 0;
  padding: 1rem;
  border: 1px dotted black;
  border-radius: 10px;
  cursor: pointer;
}

.dropzone.drag-active {
  border: 1px dashed black;
  background-color: white;
}

.emoji-button {
  background: unset;
  border: none;
}

.license-editor-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.license-editor-label {
  margin-top: .5em;
  margin-bottom: .5em;
}

.login-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 10rem);
  height: calc(100vh - 10rem);
  padding: 5rem;
  align-items: stretch;
  justify-content: space-evenly;
  font-family: Erika, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.login-container > input {
  font-family: Erika, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
  font-size: 16px;
}

.config-link {
  margin-right: 1em;
  text-decoration: none;
  color: white;
}

.config-link-active {
  text-decoration: underline;
}

th, strong {
  font-weight: normal;
  text-transform: uppercase;
}

table {
  border-spacing: 0;
  border: 1px solid black;
  border-radius: 10px;
  width: calc(100% - 2px);
}

table.editable > tbody > tr > td > input {
  color: white;
}

th, td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

tr:last-child > td {
  border-bottom: 0;
}

tbody > tr:last-child > th {
  border-bottom: 0;
}

tbody > tr > th {
  text-align: right;
}

th:last-child, td:last-child {
  border-right: 0;
}

.pagination {
  padding: 0.5rem;
}

.billing-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.billing-item:hover{
  background: white;
}

.billing-item > div {
  padding: 1rem;
}

textarea {
  width: 80%;
  font-size: 16px;
  font-family: Erika, sans-serif;
}
